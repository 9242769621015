<template>
  <div>
    <interview-close-search />
    <duplicated-result-list
      v-if="dataList.interviews.data.length > 0"
      :table-name="dataList.interviews.tableName"
      :fields="dataList.interviews.fields"
      :data-item="dataList.interviews.data"
    />
    <b-card
      v-if="dataList.interviews.data.length > 0"
      title="Görüşmeleri Kapat"
    >
      <b-row class="d-flex align-items-center">
        <b-col>
          <b-form-group
            label="Kapatma Durumu"
            label-for="id_com_interview_status"
          >
            <v-select
              id="id_com_interview_status"
              v-model="interview.id_com_interview_status"
              label="title"
              :reduce="status => status.id"
              :options="statuses"
              placeholder="Kapatma Durumu"
            />
          </b-form-group>
          <b-form-group
            label="Kapatma Notu"
            label-for="content"
          >
            <b-form-input
              v-model="interview.content"
              placeholder="Not Giriniz"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="!interview.id_com_interview_status || !interview.content"
            @click="closeInterviews"
          >
            Görüşmeleri Kapat
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCol, BRow, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import InterviewCloseSearch from '@/views/ManagerTools/Companents/InterviewClose/InterviewCloseSearchCard.vue'
import DuplicatedResultList from '@/views/ManagerTools/Companents/ResultList.vue'

export default {
  name: 'InterviewCloseCard',
  components: {
    vSelect,
    BFormGroup,
    DuplicatedResultList,
    InterviewCloseSearch,
    BCol,
    BCard,
    BRow,
    BButton,
    BFormInput,
  },
  data() {
    return {
      closeProcess: false,
      closeBtnTitle: 'Görüşmeleri Kapat',
      interview: {
        id_com_interview_status: '160',
        content: 'Destek Talebi #[...] ILE MEDUSA OTOMATİK GÖRÜŞME KAPATMA',
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['interviewCloses/dataItem']
    },
    statuses() {
      return this.$store.getters['interviewStatuses/getInterview_statuses']
    },
  },
  created() {
    this.$store.commit('interviewCloses/RESET_INTERVIEW_LIST')
    this.getInterviewStatus()
  },
  methods: {
    getInterviewStatus() {
      this.$store.dispatch('interviewStatuses/interview_statusesList')
    },
    closeInterviews() {
      this.$swal({
        title: 'Görüşme Kapatma',
        text: 'Seçilen görüşmeler belirtilen durum ve not ile hatırlatmalar dahil kapatılacak. Devam etmek istiyor musun ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('interviewCloses/interviewsClose', {
            id_com_interview_status: this.interview.id_com_interview_status,
            content: this.interview.content,
            interviews: this.dataList.interviews.data,
          })
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: 'Kayıtlar kapatılmıştır.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
