<template>
  <b-card title="Kullanıcı Seçimi">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Kullanıcı"
          label-for="id_com_user"
        >
          <v-select
            id="id_com_user"
            v-model="search.id_com_user"
            placeholder="Kullanıcı"
            :options="users"
            label="title"
            :reduce="user => user.id"
          >
            <template v-slot:option="option">
              {{ option.title }}
              <div v-if="option.user_type">
                <small class="text-warning">{{ option.user_type }}</small>
                <small class="text-muted"> / {{ option.department }}</small>
                <small class="text-muted"> / {{ option.brand }}</small>
                <small class="text-muted"> / {{ option.status ? 'Aktif' : 'Pasif' }}</small>
              </div>
            </template>
            <template v-slot:selected-option="option">
              {{ option.title }} <small class="text-warning ml-1"> {{ option.user_type }}</small>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="search.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="search.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-center">
      <b-button
        variant="primary"
        :disabled="!search.id_com_user || loadingData"
        @click="getInterviews"
      >
        <b-spinner
          v-if="loadingData"
          small
        />
        {{ searchBtnTitle }}
      </b-button>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BFormGroup, BButton, BSpinner, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'InterviewCloseSearch',
  components: {
    BFormDatepicker,
    vSelect,
    BFormGroup,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
  },
  data() {
    return {
      searchBtnTitle: 'Verileri Getir',
      search: {
        id_com_user: null,
        interview_status: null,
        sdate: null,
        edate: null,
      },
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers']
    },
    loadingData() {
      return this.$store.getters['interviewCloses/dataLoading']
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
          'com_user.status AS status',
          'com_user_type.title as user_type',
          'com_brand.name as brand',
          'com_department.title as department',
        ],
      })
    },
    getInterviews() {
      this.searchBtnTitle = 'Yükleniyor..'
      this.$store.dispatch('interviewCloses/getDataList', { id_com_user: this.search.id_com_user, edate: this.search.edate, sdate: this.search.sdate })
        .then(() => {
          this.searchBtnTitle = 'Verileri Getir'
        })
    },
  },
}
</script>
