<template>
  <b-tabs
    pills
  >
    <b-tab
      active
      lazy
    >
      <template #title>
        <feather-icon icon="CheckSquareIcon" />
        <span>Görüşme Kapatma</span>
      </template>
      <interview-close-card />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="NavigationIcon" />
        <span>Görüşme Aktarma</span>
      </template>
      <interview-transfer-card />
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import InterviewCloseCard from '@/views/ManagerTools/Companents/interviewCloseCard.vue'
import InterviewTransferCard from '@/views/ManagerTools/Companents/interviewTransferCard.vue'

export default {
  name: 'UserOperations',
  components: {
    InterviewTransferCard,
    InterviewCloseCard,
    BTab,
    BTabs,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    // erişim kısıtlama işlemi
    if (this.userData.id !== '2' && this.userData.id !== '1') {
      this.$swal({
        icon: 'error',
        title: 'Uyarı',
        text: 'Bu alana giriş için yetkiniz bulunmuyor.',
        confirmButtonText: this.$store.state.app.removeResultClose,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      this.$router.push('/')
    }
  },
}
</script>
